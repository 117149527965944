<template>
    <div>
        <!-- alert -->
        <b-alert
            v-model="showEmptyReasonAlert"
            v-height-fade.appear
            variant="danger"
            dismissible
            class="mb-1 py-1"
        >
            <div class="alert-body">
                Provide a reason why this user's request has been rejected.
            </div>
        </b-alert>
        <b-row>
            <b-col cols="8">
                <b-card>
                    <b-card-title>
                        <div class="d-flex justify-content-between">
                            <span>
                                {{ user.data.applicationCode }} -
                                <b-badge
                                    variant="light-primary"
                                    v-if="user.data.serviceType === 1"
                                    >Camtel Sim</b-badge
                                >
                                <b-badge
                                    variant="light-info"
                                    v-if="user.data.serviceType === 5"
                                    >Optical Fibre</b-badge
                                >
                                requested for
                                <b-badge
                                    variant="light-success"
                                    v-if="user.data.userType === 1"
                                    >Personal</b-badge
                                >
                                <b-badge
                                    variant="light-warning"
                                    v-if="user.data.userType === 5"
                                    >Enterprise</b-badge
                                >
                                use
                            </span>
                            <span v-if="user.data.status !== 1">
                                <b-badge
                                    v-if="user.data.status === 1"
                                    variant="info"
                                    >Pending</b-badge
                                >
                                <span class="d-flex" v-if="user.data.status === 5">
                                    <b-badge
                                    variant="success"
                                    class="d-flex align-items-center"
                                    >                                    
                                        Approved</b-badge
                                    >
                                    <!-- <feather-icon
                                        icon="ThumbsUpIcon"
                                        class="text-success"
                                        size="32">
                                    </feather-icon> -->
                                </span>
                                <b-badge
                                    v-if="user.data.status === -1"
                                    variant="warning"
                                    >Rejected</b-badge
                                >
                                <!-- <b-badge v-if="user.data.status === -2" variant="info"
                                    >ID card Expired</b-badge
                                > -->
                                <b-badge
                                    v-if="user.data.status === -5"
                                    variant="danger"
                                    >Banned</b-badge
                                >
                            </span>
                        </div>
                    </b-card-title>
                    <b-list-group class="mb-1" flush>
                        <b-list-group-item variant="light">
                            <div class="userdetails-heading">Client Info</div>
                        </b-list-group-item>
                        <b-list-group-item
                            class="
                                d-flex
                                justify-content-between
                                align-items-center
                            "
                        >
                            <span class="userdetails-label">First Name</span>

                            <div
                                class="
                                    d-flex
                                    justify-content-end
                                    align-items-center
                                "
                            >
                                <span class="px-1 userdetails-item">{{
                                    user.data.firstName
                                }}</span>
                                <!-- <span>
                                    <feather-icon
                                        :icon="reviewState"
                                        :color="reviewColor"
                                    ></feather-icon>
                                </span> -->
                            </div>
                        </b-list-group-item>
                        <b-list-group-item
                            class="
                                d-flex
                                justify-content-between
                                align-items-center
                            "
                        >
                            <span class="userdetails-label">Last Name</span>

                            <div
                                class="
                                    d-flex
                                    justify-content-end
                                    align-items-center
                                "
                            >
                                <span class="px-1 userdetails-item">{{
                                    user.data.lastName
                                }}</span>
                            </div>
                        </b-list-group-item>
                        <b-list-group-item
                            class="
                                d-flex
                                justify-content-between
                                align-items-center
                            "
                            v-if="!user.data.taxPayerNum"
                        >
                            <span class="userdetails-label">Email</span>

                            <div
                                class="
                                    d-flex
                                    justify-content-end
                                    align-items-center
                                "
                            >
                                <span class="px-1 userdetails-item">{{
                                    user.data.email
                                }}</span>
                            </div>
                        </b-list-group-item>
                        <b-list-group-item
                            class="
                                d-flex
                                justify-content-between
                                align-items-center
                            "
                            v-if="!user.data.taxPayerNum"
                        >
                            <span class="userdetails-label"
                                >Contact Number</span
                            >

                            <div
                                class="
                                    d-flex
                                    justify-content-end
                                    align-items-center
                                "
                            >
                                <span class="px-1 userdetails-item">{{
                                    user.data.phone
                                }}</span>
                            </div>
                        </b-list-group-item>
                        <b-list-group-item
                            class="
                                d-flex
                                justify-content-between
                                align-items-center
                            "
                            v-if="!user.data.taxPayerNum"
                        >
                            <span class="userdetails-label">Address</span>

                            <div
                                class="
                                    d-flex
                                    justify-content-end
                                    align-items-center
                                "
                            >
                                <span class="px-1 userdetails-item">{{
                                    user.data.address
                                }}</span>
                            </div>
                        </b-list-group-item>
                        <b-list-group-item
                            class="
                                d-flex
                                justify-content-between
                                align-items-center
                            "
                        >
                            <span class="userdetails-label"
                                >Identification Number</span
                            >

                            <div
                                class="
                                    d-flex
                                    justify-content-end
                                    align-items-center
                                "
                            >
                                <span class="px-1 userdetails-item">{{
                                    user.data.idCardNumber
                                }}</span>
                            </div>
                        </b-list-group-item>
                        <b-list-group-item
                            class="
                                d-flex
                                justify-content-between
                                align-items-center
                            "
                        >
                            <span class="userdetails-label">Date of Birth</span>

                            <div
                                class="
                                    d-flex
                                    justify-content-end
                                    align-items-center
                                "
                            >
                                <span class="px-1 userdetails-item">{{
                                    dayjs(user.data.dob).format('MMMM D, YYYY')
                                }}</span>
                            </div>
                        </b-list-group-item>
                    </b-list-group>
                    <b-list-group
                        class="mb-1"
                        flush
                        v-if="user.data.guardianCNI"
                    >
                        <b-list-group-item variant="light">
                            <div class="userdetails-heading">Guardian</div>
                        </b-list-group-item>
                        <b-list-group-item
                            class="
                                d-flex
                                justify-content-between
                                align-items-center
                            "
                        >
                            <span class="userdetails-label">Guardian Name</span>

                            <div
                                class="
                                    d-flex
                                    justify-content-end
                                    align-items-center
                                "
                            >
                                <span class="px-1 userdetails-item">{{
                                    user.data.guardianName
                                }}</span>
                            </div>
                        </b-list-group-item>
                        <b-list-group-item
                            class="
                                d-flex
                                justify-content-between
                                align-items-center
                            "
                        >
                            <span class="userdetails-label"
                                >Guardian Identification Number</span
                            >

                            <div
                                class="
                                    d-flex
                                    justify-content-end
                                    align-items-center
                                "
                            >
                                <span class="px-1 userdetails-item">{{
                                    user.data.guardianCNI
                                }}</span>
                            </div>
                        </b-list-group-item>
                    </b-list-group>
                    <b-list-group flush v-if="user.data.taxPayerNum">
                        <b-list-group-item variant="light">
                            <div class="userdetails-heading">Company</div>
                        </b-list-group-item>
                        <b-list-group-item
                            class="
                                d-flex
                                justify-content-between
                                align-items-center
                            "
                        >
                            <span class="userdetails-label">Company Name</span>

                            <div
                                class="
                                    d-flex
                                    justify-content-end
                                    align-items-center
                                "
                            >
                                <span class="px-1 userdetails-item">{{
                                    user.data.companyName
                                }}</span>
                            </div>
                        </b-list-group-item>
                        <b-list-group-item
                            class="
                                d-flex
                                justify-content-between
                                align-items-center
                            "
                        >
                            <span class="userdetails-label">Work Domain</span>

                            <div
                                class="
                                    d-flex
                                    justify-content-end
                                    align-items-center
                                "
                            >
                                <span class="px-1 userdetails-item">{{
                                    user.data.companyDomain
                                }}</span>
                            </div>
                        </b-list-group-item>
                        <b-list-group-item
                            class="
                                d-flex
                                justify-content-between
                                align-items-center
                            "
                        >
                            <span class="userdetails-label"
                                >Tax Payer Number</span
                            >

                            <div
                                class="
                                    d-flex
                                    justify-content-end
                                    align-items-center
                                "
                            >
                                <span class="px-1 userdetails-item">{{
                                    user.data.taxPayerNum
                                }}</span>
                            </div>
                        </b-list-group-item>
                        <b-list-group-item
                            class="
                                d-flex
                                justify-content-between
                                align-items-center
                            "
                        >
                            <span class="userdetails-label">Address</span>

                            <div
                                class="
                                    d-flex
                                    justify-content-end
                                    align-items-center
                                "
                            >
                                <span class="px-1 userdetails-item">{{
                                    user.data.address
                                }}</span>
                            </div>
                        </b-list-group-item>
                    </b-list-group>
                </b-card>
                <b-card>
                    <b-card-title> Description </b-card-title>
                    <b-card-text>
                        <b-form>
                            <b-row>
                                <!-- Common issues -->
                                <b-col cols="12">
                                    <b-form-group label="Issues:" :disabled="user.data.status !== 1">
                                        <b-form-checkbox-group
                                            id="knownIssues"
                                            v-model="reviewIssue"
                                            :options="reviewOptIssues"
                                            name="reviewIssue"
                                            class="demo-inline-spacing"
                                        />
                                    </b-form-group>
                                </b-col>

                                <!-- Description -->
                                <b-col cols="12">
                                    <b-form-group
                                        label="Others/Notes"
                                        label-for="reviewMessage"
                                    >
                                        <b-form-textarea
                                            id="reviewMessage"
                                            v-model="reviewReason"
                                            placeholder="Enter message..."
                                            :disabled="user.data.status !== 1"
                                        ></b-form-textarea>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <div class="d-block text-right">
                                Limit: {{ wordCountReason }}/250 words
                            </div>
                        </b-form>
                    </b-card-text>
                </b-card>
            </b-col>
            <b-col>
                <b-card>
                    <b-card-title>Documents</b-card-title>
                    <div
                        class="
                            d-flex
                            flex-column
                            justify-content-center
                            align-content-center align-items-center
                            mb-1
                        "
                        v-for="(item, index) in user.data.images"
                        :key="index"
                    >
                        <b-img
                            rounded
                            thumbnail
                            fluid
                            :src="item"
                            @click="enlargeImage(item)"
                        />
                    </div>
                </b-card>
                <b-card>
                    <b-card-title class="d-flex justify-content-between">
                        <span>Review History</span>
                    </b-card-title>
                    <b-card-text v-if="user.data.reviewHistory.length > 0">
                        This user's application has been submitted before and
                        reviewed
                        <span class="font-weight-bolder">{{
                            user.data.reviewHistory.length
                        }}</span>
                        <span v-if="user.data.reviewHistory.length < 2">
                            time.
                        </span>
                        <span v-if="user.data.reviewHistory.length > 1">
                            times.
                        </span>
                    </b-card-text>
                    <b-card-text v-else>
                        This user is submitting an application for the first time.
                    </b-card-text>
                    <div class="d-flex justify-content-end">
                        <b-button
                            :disabled="user.data.reviewHistory.length === 0"
                            class="mr-2"
                            variant="light"
                            @click="reviewHistory = true"
                        >
                            View History
                        </b-button>
                    </div>
                </b-card>
            </b-col>
        </b-row>
        <b-card v-if="idMode.mode === 'review'">
            <div
                class="
                    d-flex
                    flex-row
                    justify-content-center
                    align-content-center align-items-center
                "
            >
                <!-- <span>
                    Please read through the client's information carefully
                </span> -->
                <div>
                    <b-button
                        class="mr-4"
                        variant="warning"
                        @click="declineClient"
                        style="width: 200px"
                        >Decline</b-button
                    >
                    <b-button
                        variant="success"
                        @click="approveClient"
                        style="width: 200px"
                        >Approve</b-button
                    >
                </div>
            </div>
        </b-card>
        <b-modal
            v-model="imageModal"
            centered
            title="Fullscreen image"
            ok-only
            ok-title="Okay"
            size="lg"
        >
            <b-card-text>
                <b-img center :src="selectedImg" fluid thumbnail rounded />
                <!-- <div class="img-modal-details">
                    <b-list-group class="mb-1" flush>
                        <b-list-group-item
                            class="
                                d-flex
                                justify-content-between
                                align-items-center
                            "
                        >
                            <span class="userdetails-label">First Name</span>

                            <div
                                class="
                                    d-flex
                                    justify-content-end
                                    align-items-center
                                "
                            >
                                <span class="px-1 userdetails-item">{{
                                    user.data.firstName
                                }}</span>
                            </div>
                        </b-list-group-item>
                        <b-list-group-item
                            class="
                                d-flex
                                justify-content-between
                                align-items-center
                            "
                        >
                            <span class="userdetails-label">Last Name</span>

                            <div
                                class="
                                    d-flex
                                    justify-content-end
                                    align-items-center
                                "
                            >
                                <span class="px-1 userdetails-item">{{
                                    user.data.lastName
                                }}</span>
                            </div>
                        </b-list-group-item>
                        <b-list-group-item
                            class="
                                d-flex
                                justify-content-between
                                align-items-center
                            "
                        >
                            <span class="userdetails-label">Address</span>

                            <div
                                class="
                                    d-flex
                                    justify-content-end
                                    align-items-center
                                "
                            >
                                <span class="px-1 userdetails-item">{{
                                    user.data.address
                                }}</span>
                            </div>
                        </b-list-group-item>
                        <b-list-group-item
                            class="
                                d-flex
                                justify-content-between
                                align-items-center
                            "
                        >
                            <span class="userdetails-label">Identification Number</span>

                            <div
                                class="
                                    d-flex
                                    justify-content-end
                                    align-items-center
                                "
                            >
                                <span class="px-1 userdetails-item">{{
                                    user.data.idCardNumber
                                }}</span>
                            </div>
                        </b-list-group-item>
                    </b-list-group>
                </div> -->
            </b-card-text>
        </b-modal>
        <b-sidebar
            id="reviewHistory"
            v-model="reviewHistory"
            bg-variant="white"
            width="480px"
            right
            backdrop
            shadow
            title="Review History"
        >
            <sidebar-review-history :data="user.data" />
        </b-sidebar>
    </div>
</template>
<script>
import {
    defineComponent,
    ref,
    reactive,
    watchEffect,
    watch,
} from "@vue/composition-api";
import { useRouter } from "@/@core/utils/utils";
import { heightFade } from '@core/directives/animations'
import Ripple from 'vue-ripple-directive';
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import * as dayjs from 'dayjs';
import SidebarReviewHistory from "./SidebarReviewHistory.vue";
import API from "./api";

export default defineComponent({
    components: {
        SidebarReviewHistory,
    },
    directives: {
        'height-fade': heightFade,
        Ripple,
    },
    props: {
        idMode: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        const { router } = useRouter()
        const toast = useToast()
        const loggedInUser = reactive(
            JSON.parse(localStorage.getItem("userData"))
        );
        const user = reactive({
            data: {},
        });
        user.data.reviewHistory = [];
        const showEmptyReasonAlert = ref(false);
        const reviewIssue = ref([]);
        const reviewOptIssues = ref([
            { text: 'Identification Document has expired', value: 'Identification Document has expired' },
            { text: 'Passport photos mismatch', value: 'Passport photos mismatch' },
            { text: 'Information mismatch', value: 'Information mismatch' },
            { text: 'Insufficient Documents', value: 'Insufficient Documents' },
            { text: 'Documents not clear', value: 'Documents not clear' },
        ])
        const reviewReason = ref("");
        const wordCountReason = ref(0);
        const reviewHistory = ref(false);
        const reviewReasonCount = params => {
            let matches = params.match(/\S+/g);
            if (matches) {
                wordCountReason.value = matches.length;
            } else {
                wordCountReason.value = 0;
            }
        };
        const imageModal = ref(false);
        const selectedImg = ref("");
        const enlargeImage = imgSrc => {
            imageModal.value = true;
            selectedImg.value = imgSrc;
        };
        const approveClient = async() => {
            let stringIssues = "";
            for (let index = 0; index < reviewIssue.value.length; index++) {
                const element = reviewIssue.value[index];
                stringIssues = stringIssues.concat(element + "#");
            }

            
                const { success } = await API.review({
                    status: 5,
                    reason: stringIssues + reviewReason.value,
                    reviewer: loggedInUser.fullName,
                    id: props.idMode.id,
                });

                if (success) {
                    toast({
                        component: ToastificationContent,
                            props: {
                                title: 'Review sent!',
                                icon: 'CoffeeIcon',
                                variant: 'success',
                            },
                    })
                    router.go(-1)
                }
                
        };
        const declineClient = () => {
            let status = -1;
            let stringIssues = "";

            for (let index = 0; index < reviewIssue.value.length; index++) {
                const element = reviewIssue.value[index];
                
                // if (element == 'Identification Document has expired') {
                //     status = -2
                // }
                stringIssues = stringIssues.concat(element + "#"); 
            }

            if (!stringIssues) {
                showEmptyReasonAlert.value = true;
            } else {
                API.review({
                    status: status,
                    reason: stringIssues + reviewReason.value,
                    reviewer: loggedInUser.fullName,
                    id: props.idMode.id,
                });
                router.go(-1)
            }
        };
        watchEffect(() => reviewReasonCount(reviewReason.value));
        const getDataById = async () => {
            const response = await API.getUser(props.idMode.id);
            [user.data] = response.data.application;
        };
        getDataById();

        return {
            user,
            loggedInUser,
            reviewReason,
            reviewIssue,
            reviewOptIssues,
            reviewHistory,
            wordCountReason,
            showEmptyReasonAlert,
            reviewReasonCount,
            getDataById,
            enlargeImage,
            imageModal,
            selectedImg,
            approveClient,
            declineClient,
            dayjs
        };
    },
});
</script>
<style scoped>
/* .userdetails-item {
    color: #514f5a;
} */

.userdetails-heading {
    font-weight: 600;
}

.img-modal-details {
    width: 100%;
    background-color: rgb(255, 255, 255);
    position: absolute;
    bottom: 10px;
    left: 0px;
    right: 0px;
    padding: 8px 24px;
}

/* .userdetails-label {
    font-weight: 600;
} */
</style>
