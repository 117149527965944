<template>
    <div class="p-2">
        Review Logs: Click to view more details
        <app-collapse type="border">
            <app-collapse-item v-for="(review, index) of data.reviewHistory" :key="index" :title="dayjs(review.time).format('DD-MM-YYYY hh:mm')">
                <b-list-group>
                    <b-list-group-item class="d-flex justify-content-between align-items-center">
                        <span>
                            Review status
                        </span>
                        <span>
                            <b-badge v-if="review.status === 5" variant="light-success">Approved</b-badge>
                            <b-badge v-if="review.status === -1" variant="light-warning">Rejected</b-badge>
                            <b-badge v-if="review.status === -2" variant="light-info">ID card Expired</b-badge>
                            <b-badge v-if="review.status === -5" variant="light-danger">Banned</b-badge>
                        </span>
                    </b-list-group-item>
                    <b-list-group-item class="d-flex flex-column justify-content-between align-items-start" v-if="review.message">
                        <span class="mb-1">
                            Remarks
                        </span>
                        <div v-for="(item, index) in review.message.split('#')" :key="index" class="d-flex flex-row justify-content-start align-content-center">
                            <span class="mr-1">
                                <feather-icon style="color: #ffc107" icon="CircleIcon"></feather-icon>
                            </span>
                            <span>{{ item }}</span>
                        </div>
                    </b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between align-items-center" v-if="review.reviewer">
                        <span>
                            Reviewer
                        </span>
                        <span>
                            {{ review.reviewer }}
                        </span>
                    </b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between align-items-center">
                        <span>
                            Date-Time
                        </span>
                        <span>
                            {{ dayjs(review.time).format('DD/MM/YYYY - hh:mm:ss') }}
                        </span>
                    </b-list-group-item>
                </b-list-group>
            </app-collapse-item>
        </app-collapse>

        <!-- <div class="accordion" role="tablist">
            <b-card no-body class="mb-1" v-for="(review, index) of data.reviewHistory" :key="index">
            <b-card-header header-tag="header" class="p-1" role="tab">
                <div block v-b-toggle.customId>{{ dayjs(review.time).format('DD/MM/YYYY - hh:mm:ss') }}</div>
            </b-card-header>
            <b-collapse :id="customId(index)" visible accordion="my-accordion" role="tabpanel">
                <b-card-body>
                <b-card-text>I start opened because <code>visible</code> is <code>true</code></b-card-text>
                <b-card-text>Text</b-card-text>
                </b-card-body>
            </b-collapse>
            </b-card>
        </div> -->
    </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import * as dayjs from "dayjs";

export default defineComponent({
    components: {
        AppCollapse,
        AppCollapseItem,
    },
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    setup(data) {
        // const customId = value => 'reviewItem-' + value;
        return {
            // customId,
            dayjs
        }
    },
})
</script>
<style scoped>
.display-rejection-reasons {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
}
</style>
