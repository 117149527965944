import request from "@/services/request"
import baseUrl from "@/services/baseUrl"

// this is for globally used APIs only

let servicePath = "/camtel/v1/onboarding/applications/";
const serviceBaseUrl = baseUrl.main + servicePath;

export default {
  getList: (params = {}) => request.post(serviceBaseUrl + "list", params),
  getUser: userId => request.get(serviceBaseUrl + userId),
  review: params => request.post(serviceBaseUrl + "review", params)
}